import { paths as authPaths, routes as authRoutes } from './auth';
import {
  paths as dashboardPaths,
  routes as dashboardRoutes,
} from './dashboard';
import * as companies from './companies';
import * as fiscal from './fiscal';
import * as user from './user';
import * as customs from './customs';
import * as admin from './admin';
import * as about from './about';
import * as notifications from './admin/news';
import * as operator from './operator';
import * as intrastat from './intrastat';
import * as agencies from './agencies';
import * as payment from './payment';
import * as contact from './contact';
import * as internalTools from './InternalTools';

export const routes = [
  ...authRoutes,
  ...dashboardRoutes,
  ...customs.routes,
  ...intrastat.routes,
  ...companies.routes,
  ...fiscal.routes,
  ...user.routes,
  ...admin.routes,
  ...notifications.routes,
  ...about.routes,
  ...operator.routes,
  ...agencies.routes,
  ...payment.routes,
  ...contact.routes,
  ...internalTools.routes,
];

export const paths: any = {
  auth: authPaths,
  dashboard: dashboardPaths,
  customs: customs.paths,
  intrastat: intrastat.paths,
  companies: companies.paths,
  fiscal: fiscal.paths,
  user: user.paths,
  admin: admin.paths,
  about: about.paths,
  operator: operator.paths,
  agencies: agencies.paths,
  payment: payment.paths,
  contact: contact.paths,
  notifications: notifications.paths,
  internalTools: internalTools.paths,
};
