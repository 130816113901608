import React from 'react';

import {
  CardStyled,
  LogoStyled,
  DescriptionStyled,
  TagsStyled,
  TagStyled,
  DescriptionTextStyled,
  TitleStyled,
} from './serviceCardStyles';


type ServiceCardProps = {
  logo: string;
  name: string;
  description: string;
  tags: Array<string>;
  color: string;
  imageSize?: string;
  bgPosition: {
    x: string;
    y: string;
  };
};

const ServiceCard: React.FunctionComponent<ServiceCardProps> = (props) => {

  const urlLogo = `${process.env.REACT_APP_DEV_API_URL}/api/v1/${props.logo}`
  
  return (
    <CardStyled bgPosition={props.bgPosition}>
      <LogoStyled>
        <img
          height={props.imageSize || '80%'}
          src={urlLogo}
          alt={`logo ${props.name}`}
        ></img>
      </LogoStyled>
      <DescriptionStyled>
        <TitleStyled>{props.name}</TitleStyled>
        <DescriptionTextStyled>{props.description}</DescriptionTextStyled>
        <TagsStyled>
          {props.tags.map((tag, i) => (
            <TagStyled key={i} color={props.color}>
              {tag}
            </TagStyled>
          ))}
        </TagsStyled>
      </DescriptionStyled>
    </CardStyled>
  );
};

export default ServiceCard;
