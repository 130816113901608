import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Header, Segment } from 'semantic-ui-react';
import EmptyTable from 'components/EmptyTable';
import { Period, Spacer, State, Title } from './styles';

const Index = ({
  data,
  iso,
  monitoringPathToDetail
}: IntrastatMainListProps) => {

  const intro = data.filter((e) => e.flow === 'introduction')
  const expe = data.filter((e) => e.flow === 'expedition')
  const none = data.filter((e) => e.flow === 'none')


  const buildLink = (companyId: number, debId: string, iso?: string) => {
    return iso ?
    `/intrastat/country/:iso/:companyId/:intrastatId`.replace(':iso', iso).replace(':companyId', `${companyId}`).replace(':intrastatId', debId) : monitoringPathToDetail ?
    `/intrastat/:companyId/:intrastatId`.replace(':companyId', `${companyId}`).replace(':intrastatId', debId) :
    `/intrastat-dashboard/:companyId/:intrastatId`.replace(':companyId', `${companyId}`).replace(':intrastatId', debId)
  }


  return (
    <>
      {data === null && (
        <Segment>
          <Header
            textAlign='center'
            size='small'
          >{`No intrastat have been uploaded.`}</Header>
        </Segment>
      )}

      {data !== null && (
        <Table sortable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Filing Period
              </Table.HeaderCell>
              <Table.HeaderCell>
                Country
              </Table.HeaderCell>
              <Table.HeaderCell>
                Created at
              </Table.HeaderCell>
              <Table.HeaderCell>
                Customer
              </Table.HeaderCell>
              <Table.HeaderCell>
                Type
              </Table.HeaderCell>             
              <Table.HeaderCell>
                Flow
              </Table.HeaderCell>
              <Table.HeaderCell>
                Emitter
              </Table.HeaderCell> 
              <Table.HeaderCell>
                Followers
              </Table.HeaderCell>                         
              <Table.HeaderCell>
                State
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
          {(data && data.length === 0 && <EmptyTable colNumber={9} />) || (
            <>
            {expe.length>0 && (
              <Table.Row verticalAlign='middle'>
                <Title colSpan={9} style={{background: 'rgb(255, 198, 0)', padding: '6px 0 4px 10px', fontWeight: 'bold'}}> ⇐ Expedition</Title>
              </Table.Row>
            )}    
            {expe.map((e, i) => {
              return (
                <Table.Row key={i} verticalAlign='middle'>
                  <Link
                    to={{pathname: buildLink(e.Company_Id, `${e.id}`, iso), ...monitoringPathToDetail && {state: {locPath: 'monitoring'}}}} className='table-link'
                  >
                  <Table.Cell key={i+1} style={{ width: '170px'}}>
                    <Period>{new Date(e.period).toLocaleDateString('en', {
                      month: 'long',
                    })}
                    <Spacer>{new Date(e.period).toLocaleDateString('en', {
                        year: 'numeric',
                      })}</Spacer>
                    </Period>                                     
                  </Table.Cell> 
                  <Table.Cell key={i+2}> 
                      {e.filingCountry}
                  </Table.Cell>                 
                  <Table.Cell key={i+3} style={{ width: '150px'}}> 
                  {new Date(e.createdAt).toLocaleDateString('en', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}</Table.Cell>

                  <Table.Cell key={i+4} style={{ width: '250px'}}>{e.Company.name}</Table.Cell>
                  <Table.Cell key={i+5} style={{ width: '150px'}}>{e.declarationType}</Table.Cell>
                  <Table.Cell key={i+6} style={{ width: '150px'}}>{e.flow}</Table.Cell>
                  <Table.Cell key={i+7} style={{ width: '250px'}}>{e.emitter}</Table.Cell>
                  <Table.Cell key={i+8} style={{ width: '250px'}}>{e.follower && (
                    <ul style={{ margin: '0'}}>
                      {e.follower.map((e, i) => (
                        <li key={i}>{e.operator}</li>
                      ))}
                    </ul>
                  )}</Table.Cell>
                  <Table.Cell key={i+9}> <State defaultValue={
                     !e.DeclarationHasCategory ? 'red' : e.DeclarationHasCategory.step === 1 ? 
                      '#00619b' :  e.DeclarationHasCategory.step === 4 ? 
                      '#009900' : (e.DeclarationHasCategory.step === 2 && e.DeclarationHasCategory.step === 3) ? 
                      '#00619b' : '#dda80b'}>{!e.DeclarationHasCategory ? 'Canceled' : e.DeclarationHasCategory.step === 1 ? 'Unprocessed' : e.state}</State></Table.Cell>                
                </Link>
                </Table.Row>          
              );
            })}
            {none.length>0 && (
               <Table.Row verticalAlign='middle'>
               <Title colSpan={9} style={{background: 'grey', color: 'black', padding: '6px 0 4px 10px', fontWeight: 'bold'}}>Néant / None</Title>
             </Table.Row>
            )}
            {none.map((e, i) => {
              return (
                <Table.Row key={i} verticalAlign='middle'>
                   <Link to={{pathname: buildLink(e.Company_Id, `${e.id}`, iso), ...monitoringPathToDetail && {state: {locPath: 'monitoring'}}}} className='table-link'
                    >
                  <Table.Cell key={i+1} style={{width: '170px'}}>
                  <Period>{new Date(e.period).toLocaleDateString('en', {
                      month: 'long',
                    })}
                    <Spacer>{new Date(e.period).toLocaleDateString('en', {
                        year: 'numeric',
                      })}</Spacer>
                    </Period>                                     
                  </Table.Cell>
                  <Table.Cell key={i+2}> 
                      {e.filingCountry}
                  </Table.Cell>                                
                  <Table.Cell key={i+3} style={{width: '150px'}}> 
                  {new Date(e.createdAt).toLocaleDateString('en', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}</Table.Cell>   
                  <Table.Cell key={i+4} style={{ width: '250px'}}>{e.Company.name}</Table.Cell>
                  <Table.Cell key={i+5} style={{ width: '150px'}}>{e.declarationType.toUpperCase() === 'NONE' && 'NONE'}</Table.Cell>
                  <Table.Cell key={i+6} style={{ width: '150px'}}>{e.flow.toUpperCase() === 'NONE' && ''}</Table.Cell>
                  <Table.Cell key={i+7} style={{ width: '250px'}}>{e.emitter}</Table.Cell>
                  <Table.Cell key={i+8} style={{ width: '250px'}}>{e.follower && (
                    <ul style={{ margin: '0'}}>
                      {e.follower.map((e, i) => (
                        <li key={i}>{e.operator}</li>
                      ))}
                    </ul>
                  )}</Table.Cell>
                  <Table.Cell key={i+9} style={{width: '250px'}}>
                    <State defaultValue='grey'>{e.state}</State></Table.Cell>                 
                  </Link>
                </Table.Row>        
              )})}

            {intro.length>0 && (
              <Table.Row verticalAlign='middle'>
                <Title colSpan={9} style={{background: 'rgb(50, 117, 193)', color: 'white', padding: '6px 0 4px 10px', fontWeight: 'bold'}}>Introduction ⇒</Title>
              </Table.Row>
            )}          
            {intro.map((e, i) => {
              return (
                <Table.Row key={i} verticalAlign='middle'>
                   <Link to={{pathname: buildLink(e.Company_Id, `${e.id}`, iso), ...monitoringPathToDetail && {state: {locPath: 'monitoring'}}}} className='table-link'
                    >
                  <Table.Cell key={i+1} style={{ width: '170px'}}>
                    <Period>{new Date(e.period).toLocaleDateString('en', {
                      month: 'long',
                    })}
                     <Spacer>{new Date(e.period).toLocaleDateString('en', {
                      year: 'numeric',
                    })}</Spacer></Period>                                     
                  </Table.Cell> 
                  <Table.Cell key={i+2}> 
                      {e.filingCountry}
                  </Table.Cell>                                   
                  <Table.Cell key={i+3} style={{ width: '150px'}}> 
                  {new Date(e.createdAt).toLocaleDateString('en', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}</Table.Cell>

                  <Table.Cell key={i+4} style={{ width: '250px'}}>{e.Company.name}</Table.Cell>
                  <Table.Cell key={i+5} style={{ width: '150px'}}>{e.declarationType}</Table.Cell>
                  <Table.Cell key={i+6} style={{ width: '150px'}}>{e.flow}</Table.Cell>
                  <Table.Cell key={i+7} style={{ width: '250px'}}>{e.emitter}</Table.Cell>
                  <Table.Cell key={i+8} style={{ width: '250px'}}>{e.follower && (
                    <ul style={{ margin: '0'}}>
                      {e.follower.map((e, i) => (
                        <li key={i}>{e.operator}</li>
                      ))}
                    </ul>
                  )}</Table.Cell>
                  <Table.Cell key={i+9} style={{ width: '250px'}}>
                    <State defaultValue={
                       !e.DeclarationHasCategory ? 'red' : e.DeclarationHasCategory.step === 1 ? 
                       '#00619b' :  e.DeclarationHasCategory.step === 4 ? 
                       '#009900' : (e.DeclarationHasCategory.step === 2 && e.DeclarationHasCategory.step === 3) ? 
                       '#00619b' : '#dda80b'}>{!e.DeclarationHasCategory ? 'Canceled' : e.DeclarationHasCategory.step === 1 ? 'Unprocessed' : e.state}</State></Table.Cell>  
                  </Link>
                </Table.Row>        
              )})}
          </>
          )}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

interface IntrastatMainListProps {
  data: Array<{
    id: number;
    Company: any;
    Company_Id: number;
    flow: string;
    emitter: string;
    follower: any;
    declarationType: string;
    DeclarationHasCategory: any;
    period: string;
    filingCountry: string;
    state: string;
    createdAt: string;
  }>;
  companyId?: number;
  iso?: string;
  monitoringPathToDetail?: boolean;
}

export default Index;
