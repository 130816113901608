import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Menu, Image, Icon } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';

import images from 'assets/images';
import NavLinkImg from 'components/NavLinkImg';
import NavLinkCustom from 'components/NavLinkCustom';
import NotificationBell from 'components/Layouts/Notification/NotificationBell';
import {
  MenuStyled,
  MenuItemStyled,
  MenuItemWrapper,
  DropdownItemStyled,
  DropdownMenuStyled,
  DropdownStyled,
  SubMenuItem,
  SubMenuWrapper,
  UserItems,
  UserItemsBullet,
  DropdownSrollStyled,
  Disconnect,
  Btn,
} from './styles';

const Index = ({
  dispatch,
  connections,
  removeCookie,
  userCompanies,
  userCompaniesSelected,
  user,
  history,
  logout,
  resetError,
}: any) => {
  const subMenuRef = useRef(null);

  const [subMenuIsOpen, setsubMenuIsOpen] = useState(false);
  const [rolePerms, setRolePerms] = useState(false);

  useEffect(() => { 
    dispatch(actions.socket.subscribeConnectedUsers())
    dispatch(actions.socket.subscribeAppState(user.email))

    user && setRolePerms(user.role && user.role.find((e) => e.role === 'commercial'))
    const handleClickOutside = (event) => {
      if (
        subMenuRef.current &&
        !subMenuRef.current.contains(event.target) &&
        event.target.id !== 'subMenuIcon'
      ) {
        setsubMenuIsOpen(false);
      }
    };
    if(subMenuIsOpen){
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      dispatch(actions.socket.unsubscribeConnectedUsers())
      dispatch(actions.socket.unsubscribeAppState(user.email))
      //dispatch(actions.socket.resetConnexions(['connection']))
    };

  }, [dispatch, subMenuIsOpen, user, userCompanies]);


  const closeSubMenu = () => setsubMenuIsOpen(false);

  // useEffect(() => {
  //   if(removeCookie === 1){
  //       window.open(window.location.pathname)
  //     }
  // }, [window, removeCookie, connections])

  const closeConnections = useCallback(() => {
    dispatch(actions.socket.removeCookies({
      data: {
        action: 'dispatchActions',
        connections: connections
      }
    }))
    return () => {
      dispatch(actions.socket.reset(['remove']))
    }
  }, [dispatch, connections])

  return (
    <MenuStyled fluid pointing secondary size='massive'>
      <Menu.Item>
        <NavLinkImg to='/'>
          <Image width='60px' src={images.logo_asd_white} />
        </NavLinkImg>
      </Menu.Item>
      <MenuItemWrapper>
        <DropdownStyled item text='Fiscal'>
          <DropdownMenuStyled>          
           
            <NavLink to='/fiscal/taxflow'>
              <DropdownItemStyled onClick={() => resetError()}>
                TaxFlow
              </DropdownItemStyled>
            </NavLink>

            <NavLink to='/fiscal/collectionbox'>
              <DropdownItemStyled onClick={() => resetError()}>
                Collection box
              </DropdownItemStyled>
            </NavLink>
          
            {((user.admin || user.operator) && (
              <>
              <NavLink to='/fiscal/declaration'>
                <DropdownItemStyled onClick={() => resetError()}>
                  VAT declaration
                </DropdownItemStyled>
              </NavLink>
              <NavLink to='/fiscal/oss'>
                <DropdownItemStyled onClick={() => resetError()}>
                  Oss
                </DropdownItemStyled>
              </NavLink>
              </>
            ))}
            <NavLink to='/fiscal/kyanite'>
              <DropdownItemStyled onClick={() => resetError()}>
                Quickproof
              </DropdownItemStyled>
            </NavLink>
          
          </DropdownMenuStyled>
        </DropdownStyled>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <DropdownStyled item text='Customs'>
          <DropdownMenuStyled>
              <NavLink to='/customs/declaration'>
                <DropdownItemStyled>Declaration</DropdownItemStyled>
              </NavLink>
            {((user.admin || user.operator) && (
              <NavLink to='/customs/clerk'>
                <DropdownItemStyled>Clerk</DropdownItemStyled>
              </NavLink>
            ))}
          </DropdownMenuStyled>
        </DropdownStyled>
      </MenuItemWrapper>
      {((user.admin || user.operator) && (
        <>
        <MenuItemWrapper>
          <DropdownStyled item text='Internal Tools'>
            <DropdownMenuStyled>
              <NavLink to='/internal-tools/ssr'>
                <DropdownItemStyled onClick={() => resetError()}>
                  SSR
                </DropdownItemStyled>
              </NavLink>
              {rolePerms && (
                <NavLink to='/internal-tools/commercial-offer'>
                  <DropdownItemStyled onClick={() => resetError()}>
                    Commercial offer
                  </DropdownItemStyled>
                </NavLink>
              )}            
            </DropdownMenuStyled>
          </DropdownStyled>
        </MenuItemWrapper>
       </>
      ))}
      <MenuItemWrapper>
      {(userCompaniesSelected && (
        <DropdownStyled item text='Intrastat'>
        <DropdownMenuStyled>
          <NavLink to={`/intrastat-dashboard`}>
            <DropdownItemStyled onClick={() => resetError()}>
            Intrastat
            </DropdownItemStyled>
          </NavLink>
        </DropdownMenuStyled>
        </DropdownStyled>
      )) || (
        <DropdownStyled item text='Intrastat'>
        <DropdownMenuStyled>
          <NavLink to='/intrastat'>
            <DropdownItemStyled onClick={() => resetError()}>
            Intrastat
            </DropdownItemStyled>
          </NavLink>
        </DropdownMenuStyled>
        </DropdownStyled>
      )}
       
      </MenuItemWrapper>
      <Menu.Menu position='right'>
        <NavLinkCustom to='/companies'>
          <MenuItemStyled name='companies' onClick={() => resetError()} />
        </NavLinkCustom>

        {((!user.admin || !user.operator) && ( 
          <NavLinkCustom to='/news'>
            <MenuItemStyled name='news' onClick={() => resetError()} />
          </NavLinkCustom>
        ))}

        {user.admin && (
          // || user.superOp
          <NavLinkCustom to='/agencies'>
            <MenuItemStyled name='agencies' onClick={() => resetError()} />
          </NavLinkCustom>
        )}

        {user.admin && (
          // || user.superOp
          <NavLinkCustom to='/operator'>
            <MenuItemStyled name='operator' onClick={() => resetError()} />
          </NavLinkCustom>
        )}
        {user.admin && (
          <MenuItemWrapper>
            <DropdownStyled item text='Admin'>
              <DropdownMenuStyled>
                <NavLink to='/admin/users'>
                  <DropdownItemStyled onClick={() => resetError()}>
                    Users
                  </DropdownItemStyled>
                </NavLink>
                <NavLink to='/admin/news'>
                  <DropdownItemStyled onClick={() => resetError()}>
                    News
                  </DropdownItemStyled>
                </NavLink>
              </DropdownMenuStyled>
            </DropdownStyled>
          </MenuItemWrapper>
        )}
        {user.superOperator && (
          <MenuItemWrapper>
            <DropdownSrollStyled 
              item 
              text='Sockets'
              options={
                connections && 
                connections.length && 
                connections.map((e, index) => (
              <>
              {index === 0 && ( 
                <Disconnect
                ><Btn
                onClick={() => {
                  closeConnections()
                }} 
                />Disconnect all</Disconnect>
              )} 
              {index+1 >= 1 && ( 
                <UserItems>
                  <UserItemsBullet></UserItemsBullet>{e.email}
                </UserItems>
              )} 
                </>
              ))}
              >
            </DropdownSrollStyled>
          </MenuItemWrapper>        
        )}
        {user &&
          !user.admin &&
          !user.operator &&
          userCompanies &&
          userCompanies.length > 0 && (
            <MenuItemWrapper>
              <DropdownStyled
                item
                text={
                  (userCompaniesSelected &&
                    userCompanies.find((e) => e.id === userCompaniesSelected) &&
                    userCompanies.find((e) => e.id === userCompaniesSelected)
                      .name) ||
                  'No company selected'
                }
                onClick={() =>
                  dispatch(
                    actions.auth.getUserCompanies({
                      params: { userId: user.id },
                    })
                  )
                }
              >
                <DropdownMenuStyled>
                  <DropdownItemStyled
                    onClick={() => {
                      resetError();
                      dispatch({
                        type: 'MY_ASD.AUTH.CHANGE_COMPANY_SELECTED_SUCCESS',
                        payload: {
                          userCompaniesSelected: null,
                          userPerms: null,
                        },
                      });
                    }}
                  >
                    No company selected
                  </DropdownItemStyled>
                  {userCompanies.map((company, i) => {
                    return (
                      <DropdownItemStyled
                        key={i}
                        onClick={() => {
                          resetError();
                          dispatch(
                            actions.auth.changeCompanySelected({
                              params: {
                                companyId: company.id,
                                userId: user.id,
                              },
                            })
                          );
                        }}
                      >
                        {company.name}
                      </DropdownItemStyled>
                    );
                  })}
                </DropdownMenuStyled>
              </DropdownStyled>
            </MenuItemWrapper>
          )}
        <MenuItemWrapper margin='auto 10px auto auto'>
          <Icon
            id='subMenuIcon'
            className='cursor-pointer'
            name='ellipsis horizontal'
            inverted={!subMenuIsOpen}
            size='large'
            onClick={() => setsubMenuIsOpen(!subMenuIsOpen)}
          />
          {subMenuIsOpen && (
            <SubMenuWrapper ref={subMenuRef}>
              <NavLink to='/profile'>
                <SubMenuItem
                  onClick={() => {
                    closeSubMenu();
                    resetError();
                  }}
                >
                  Profile
                </SubMenuItem>
              </NavLink>
              {!user.admin && !user.operator && (
                <NavLink to='/contact'>
                  <SubMenuItem onClick={closeSubMenu}>Contact Us</SubMenuItem>
                </NavLink>
              )}
              <NavLink to='/about'>
                <SubMenuItem onClick={closeSubMenu}>About</SubMenuItem>
              </NavLink>
              <SubMenuItem
                onClick={() => {
                  resetError();
                  logout();
                }}
              >
                Logout
              </SubMenuItem>
            </SubMenuWrapper>
          )}
        </MenuItemWrapper>
        <MenuItemWrapper margin='auto'>
          <NotificationBell />
        </MenuItemWrapper> 
      </Menu.Menu>
    </MenuStyled>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    userCompanies: selectors.auth.userCompanies(state),
    userCompaniesSelected: selectors.auth.companySelected(state),
    connections: selectors.socket.connectionSelector(state),
    removeCookie: selectors.socket.removeCookieSelector(state)
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(Index);
