import { ThemeProvider } from 'emotion-theming';
import { createBrowserHistory } from 'history';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { selectors, actions } from 'store';
import Cookies from 'js-cookie';

import FloatingMessage from 'components/FloatingMessage/FloatingMessage';
import { routes } from 'routes';
import { Body } from 'components/Layouts';
import { PrivateRoute, PublicRoute } from 'components/Routes';
import theme from 'theme';
import NoMatch from 'views/NoMatch';
import Error from 'views/Error';
import { Footer, Navigation, Main, SPW } from 'components/Layouts';
import { Sidebar } from 'components/Layouts';
import NotificationModal from 'components/Layouts/Notification/Notification';
import CookiesBanner from 'components/CookiesBanner/CookiesBanner';

const history = createBrowserHistory();

const App = ({
  dispatch,
  isAuthenticated,
  user,
  companySelected,
  message,
  getError,
  httpCode,
  numberNotifications,
}: any) => {
  const [visible, setvisible] = useState(false);
  const [consentCookiesPolicy, setconsentCookiesPolicy] = useState(false);

   useEffect(() => {
    dispatch(actions.auth.getNewUser())
    return () => {
      dispatch(actions.auth.reset(['newuser', 'error']));
    }
  }, [dispatch]) 

  useEffect(() => {
    checkConsentCookies();
    const unlisten = history.listen((location, action) => {
      checkConsentCookies();
      dispatch(actions.message.reset(['httpCode', 'message', 'error']));
    });

    return () => {
      unlisten();
    };
  }, [dispatch]);

  const checkConsentCookies = () => {
    Cookies.get('consentCookiesPolicy')
      ? setconsentCookiesPolicy(true)
      : setconsentCookiesPolicy(false);
  };

  useEffect(() => {
    document.title = `${
      (numberNotifications > 0 && `(${numberNotifications}) `) || ''
    }MyASD: software platform for international growth | ASD Group`;
  }, [numberNotifications]);

  const logout = () => {
    dispatch(actions.socket.unsubscribeUserCompanies(user.id));
    companySelected &&
      dispatch(
        actions.socket.unsubscribeUpdatePermissionsCompanySelected(
          user.id,
          companySelected
        )
      );
    dispatch(actions.auth.logout());
  };

  if (httpCode === 401) {
    dispatch(actions.message.reset(['httpCode']));
    dispatch(actions.auth.removeAuth());
  }

  return (
    <ThemeProvider theme={theme.getTheme()}>
      <Router history={history}>
      {(
        isAuthenticated && 
        history.location.pathname === '/spw' && 
        <SPW>spw</SPW>
        ) || 
        <Body>
          <Main>
            {!getError && message !== null && (
              <FloatingMessage dispatch={dispatch} />
            )}
            <CookiesBanner
              open={!consentCookiesPolicy}
              handleClose={() => setconsentCookiesPolicy(true)}
            />
            <Sidebar setvisible={setvisible} visible={visible} logout={logout}>
              {isAuthenticated && (
                <>
                  <Navigation
                    logout={logout}
                    history={history}
                    openSidebar={() => setvisible(true)}
                  />
                  <NotificationModal dispatch={dispatch} />
                </>
              )}
              {getError && (
                <Route
                  history={history}
                  dispatch={dispatch}
                  component={Error}
                />
              )}
              {!getError && (
                <>
                  <Switch>
                    {routes.map((route) => {
                      if (route.private) {
                        return (
                          <PrivateRoute
                            exact
                            path={route.path}
                            key={route.path}
                            authenticated={isAuthenticated}
                            authorized={route.authorizedRoles}
                            history={history}
                            dispatch={dispatch}
                            Component={route.component}
                          />
                        );
                      }
                      return (
                        <PublicRoute
                          exact
                          path={route.path}
                          key={route.path}
                          Component={route.component}
                        />
                      );
                    })}
                    <Route component={NoMatch} />
                  </Switch>
                </>
              )}
            </Sidebar>
            <Footer history={history} user={user} />
          </Main>
        </Body>
      }
      </Router>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: selectors.auth.isAuthenticatedSelector(state),
    user: selectors.auth.userSelector(state),
    newuser: selectors.auth.newuserSelector(state),
    companySelected: selectors.auth.companySelected(state),
    message: selectors.message.messageSelector(state),
    getError: selectors.message.getErrorSelector(state),
    httpCode: selectors.message.httpCodeSelector(state),
    numberNotifications: selectors.notifications.numberUnreadNotificationSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(App);
