import React from 'react';

const PartnersDocumentsToAdd = ({ docList, comments }: PartnersDocumentsToAddI) => {

  return (
    <>
      <br></br>                      
      <div><b>Documents to add: </b>{comments && (comments)}</div>
      <div>
        {docList.map((doc) => (
          doc.add && (
          <>
            <span key={doc.id}>&nbsp;&nbsp;-&nbsp;&nbsp;{doc.name}</span>
            <br></br>
          </>
          )                           
        ))}
      </div>                      
    </>
  );

}

interface PartnersDocumentsToAddI {
  docList: any;
  comments: string;
}

export default PartnersDocumentsToAdd;
