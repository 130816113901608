import styled from "@emotion/styled";

export const Wrapper = styled.div(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
}));

export const LoaderWrapper = styled.div(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    height: '100vh',
    width: '100vw',
}))
