import React, { useEffect, useMemo } from 'react';
import { createMedia } from '@artsy/fresnel';
import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import NavigationMobile from './NavigationMobile';
import NavigationDesktop from './NavigationDesktop';

const Index = ({
  dispatch,
  userCompanies,
  userCompaniesSelected,
  logout,
  history,
  openSidebar,
  user,
}: any) => {
  useEffect(() => {
    dispatch(actions.socket.subscribeUserCompanies(user.id));
    if (
      userCompanies &&
      userCompanies.length === 0 &&
      userCompaniesSelected &&
      userCompaniesSelected !== null
    ) {
      dispatch({
        type: 'MY_ASD.AUTH.CHANGE_COMPANY_SELECTED_SUCCESS',
        payload: {
          userCompaniesSelected: null,
          userPerms: null,
        },
      });
    }

    return () => {
      dispatch(actions.socket.unsubscribeUserCompanies(user.id));
    };
  }, [dispatch, userCompanies]);

  const resetError = () => {
    dispatch(actions.message.reset(['httpCode', 'getError', 'message']));
  };

  const AppMedia = createMedia({
    breakpoints: {
      mobile: 0,
      computer: 1200,
    },
  });

  const mediaStyles = AppMedia.createMediaStyle();
  const { Media, MediaContextProvider } = AppMedia;

  const ResponsiveMenu = useMemo(
    () => (
      <>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <Media at='mobile'>
            <NavigationMobile
              resetError={resetError}
              openSidebar={openSidebar}
            />
          </Media>
          <Media greaterThanOrEqual='computer'>
            <NavigationDesktop
              logout={logout}
              history={history}
              resetError={resetError}
            />
          </Media>
        </MediaContextProvider>
      </>
    ),
    []
  );

  return <>{ResponsiveMenu}</>;
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    userCompanies: selectors.auth.userCompanies(state),
    userCompaniesSelected: selectors.auth.companySelected(state),
    userPermissions: selectors.auth.userPermissions(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(Index);
