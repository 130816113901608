import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { paths } from 'routes';
import { selectors, actions } from 'store';
import NewsModal from 'components/NewsModal/newsModal';
import FloatingCard from 'components/core/FloatingCard/FloatingCard';
import FloatingCardSingle from 'components/core/FloatingCard/FloatingCardSingle';
import Carousel from 'react-elastic-carousel'
import FloatingCardReload from 'components/core/FloatingCard/FloatingCardReload';
import FloatingCardSingleReload from 'components/core/FloatingCard/FloatingCardSingleReload';

const Index = ({
  isAuthenticated,
  dispatch,
  history,
  exact,
  path,
  Component,
  user,
  authorized,
  services,
  notOpenedNews
}) => {
  const currentPath = window.location.pathname
  const [newsModalOpen, setnewsModalOpen]: any = useState(false);
  const [newsToDisplay, setnewsToDisplay]: any = useState(null);
  const [modalContent, setModalContent]: any = useState(null);
  const [isRead, setisRead]: any = useState(false);
  const [serviceId, setService]: any = useState(0);

  useEffect(() => {
    dispatch(actions.services.getAll())
    return () => {
      dispatch(actions.services.reset(['services', 'error']))
    }
  }, [dispatch]);


  useEffect(() => {
    if(services){
      const service = services.filter((e) => e.path === currentPath)

      // 101 = dashbbord
      const service_id = service.length>0 ? service[0].id : currentPath === '/' ? 101 : currentPath === '/spw' ? null : null
      if(service_id){
        setService(service_id)
        dispatch(actions.socket.subscribeReadNews(user.id, service_id))
        dispatch(
          actions.news.notOpenedNews({
            params: { userId: user.id, service: service_id},
          })
        );
        return () => {
          dispatch(actions.socket.unsubscribeReadNews(user.id, service_id))
          dispatch(actions.news.reset(['notOpenedNews', 'error']));
        }
      }else{
        // no service
        setnewsToDisplay(null)
      }  
    }else{
      setnewsToDisplay(null)
    }

  }, [dispatch, currentPath])

  useEffect(() => {
    if(notOpenedNews){
      const tab = []
      notOpenedNews.forEach((el, i) => {
        if(el.service === "99"){
          tab.unshift({
            id: el.id,
            title: el.name,
            service: el.service,
            children: el.message
          })
        }else{
          tab.push({
            id: el.id,
            title: el.name,
            service: el.service,
            children: el.message
          })
        }
      })
      setnewsToDisplay(tab)
    }else{
      setnewsToDisplay(null)
    }
  }, [notOpenedNews])


  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (
          isAuthenticated &&
          (authorized.length === 0 ||
            user.admin ||
            authorized.filter((e) => user[e]).length > 0)
        ) {
          return (
            <>
              {modalContent && isRead === false && (
                  <NewsModal
                    open={newsModalOpen}
                    handleClose={() => {
                      setnewsModalOpen(false);
                      setisRead(true);
                      dispatch(
                        actions.news.readNews({
                          params: { 
                            newsId: modalContent.id, 
                            userId: user.id, 
                            service: serviceId ? serviceId : '101'},
                        })
                      );
                      setModalContent(null)
              
                    }}
                    news={modalContent}
                  />
              )}
              {newsToDisplay && (newsToDisplay.length > 1) && (
                  <Carousel itemPadding={[0, 0]} outerSpacing={0} transitionMs={250} isRTL={false}>
                    {newsToDisplay.map((news, i) => (
                      (news.service !== "99" &&
                      <FloatingCard
                        key={i}
                        item={i}
                        title='Important Message'
                        icon='warning circle'
                        iconColor='red'
                        onClick={() => {                 
                          setModalContent(news) 
                          setisRead(false) 
                          setnewsModalOpen(true);              
                        }}
                      >
                        {news?.title}
                      </FloatingCard>) ||
                      <FloatingCardReload
                        key={i}
                        item={i}
                        title='Release'
                        icon='warning circle'
                        iconColor='red'
                        onClick={() => { 
                        setisRead(true);
                        dispatch(
                          actions.news.readNews({
                            params: { 
                              newsId: news.id, 
                              userId: user.id, 
                              service: '99'},
                          })
                        );                
                        window.location.href = `${window.location.href}`
                        }}
                      >
                      </FloatingCardReload>
                    ))}
                  </Carousel>
                )} 

                {
                newsToDisplay && 
                newsToDisplay.length == 1  && 
                newsToDisplay.map((news, i) => (
                  (news.service !== "99" &&
                  <FloatingCardSingle
                    key={i}
                    item={i}
                    title='Important Message'
                    icon='warning circle'
                    iconColor='red'
                    onClick={() => {                
                      setModalContent(news) 
                      setisRead(false) 
                      setnewsModalOpen(true);              
                    }}
                  >
                    {news?.title}
                  </FloatingCardSingle>) ||
                  <FloatingCardSingleReload
                    key={i}
                    item={i}
                    title='Release'
                    icon='warning circle'
                    iconColor='red'
                    onClick={() => { 
                    setisRead(true);
                    dispatch(
                      actions.news.readNews({
                        params: { 
                          newsId: news.id, 
                          userId: user.id, 
                          service: '99'},
                      })
                    );                
                    window.location.href = `${window.location.href}`
                    }}
                  >
                </FloatingCardSingleReload>
                ))}                                
              <Component {...props} user={user} />
              </>
          );
        } else if (
          isAuthenticated &&
          authorized.filter((e) => user[e]).length === 0
        ) {
          dispatch(
            actions.message.errorMessage(
              0,
              'you do not have the right to access this page'
            )
          );
        }
        return <Redirect push to={paths.auth.login} />;
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    error: selectors.message.httpCodeSelector(state),
    isAuthenticated: selectors.auth.isAuthenticatedSelector(state),
    services: selectors.service.servicesSelector(state),
    notOpenedNews: selectors.news.notOpenedNewsSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(Index);