import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { selectors, actions } from 'store';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { Main } from 'components/Layouts';
import { Message, Divider, Icon } from 'semantic-ui-react';
import { matchPath } from 'react-router';
import { routes } from 'routes';

const NoMatch = ({
  history,
  dispatch,
  message,
  isAuthenticatedUser,
  httpCode,
}: any) => {
  const [paths, setpaths] = useState([]);
  const [destinationPath, setdestinationPath] = useState(null);
  const exemptedRoutes = ['/admin/users/edit/'];

  const resetError = () => {
    dispatch(actions.message.reset(['httpCode', 'getError', 'message']));
  };

  useEffect(() => {
    if (exemptedRoutes.includes(history.location.pathname)) {
      resetError();
      history.push('/');
    }
  }, [history, exemptedRoutes, resetError]);

  useEffect(() => {
    let paths = [];
    routes.forEach((route) => {
      paths.push(route.path);
    });
    setpaths(paths);
    return () => {
      dispatch(
        actions.message.reset(['httpCode', 'message', 'error', 'getError'])
      );
    };
  }, []);

  useEffect(() => {
    let path = history.location.pathname;
    if (paths.length > 0) {
      do {
        path = getBackPath(path);
      } while (isNotExactMatch(path));
    }
  }, [paths]);

  const isNotExactMatch = (path) => {
    const match = matchPath(path, {
      path: paths,
      exact: true,
      strict: true,
    });
    if (match !== null && !match.path.includes('/:'))
      setdestinationPath(match.path);
    return match === null || match.path.includes('/:');
  };

  const getBackPath = (path) => {
    if (path !== '/') {
      do {
        path = path.substring(0, path.length - 1);
      } while (path.slice(-1) !== '/');
      if (path !== '/') path = path.substring(0, path.length - 1);
    } else {
      return path;
    }

    return path;
  };

  const redirect = () => {
    resetError();
    destinationPath ? history.push(destinationPath) : history.goBack();
  };

  return (
    <Main>
      <Wrapper width='40%' margin='1.5rem auto 1.5rem auto'>
        <Message error icon>
          <Icon name='warning sign' />
          <Message.Content>
            <Message.Header>Error</Message.Header>
            <p>{message}</p>
            {httpCode && httpCode.toString()[0] !== '4' && (
              <>
                <Divider />
                <p>
                  Try to reload the page. If the error persists, please contact
                  us.
                </p>
              </>
            )}
          </Message.Content>
        </Message>
      </Wrapper>
      {isAuthenticatedUser ? (
        <Wrapper display='flex' justifyContent='space-between'>
          <Button
            content='Reload'
            type='button'
            onClick={() => {
              resetError();
            }}
            margin='5px'
          />
          <Button
            content='Back'
            icon='left arrow'
            type='button'
            labelPosition='right'
            onClick={() => {
              redirect();
            }}
            margin='5px'
          />
        </Wrapper>
      ) : (
        <Button
          content='Reload'
          type='button'
          onClick={() => {
            resetError();
          }}
          margin='5px'
        />
      )}
    </Main>
  );
};
const mapStateToProps = (state) => {
  return {
    message: selectors.message.messageSelector(state),
    httpCode: selectors.message.httpCodeSelector(state),
    isAuthenticatedUser: selectors.auth.isAuthenticatedSelector(state),
  };
};
const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(NoMatch);
