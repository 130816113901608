import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Divider } from 'semantic-ui-react';

import TextCustom from 'components/core/TextCustom/TextCustom';
import Wrapper from 'components/core/Wrapper/Wrapper';
import ServiceCard from './components/ServiceCard/ServiceCard';
import services from 'config/services.json';
import { CardWrapper, ExternalLink } from './dashboardStyles';

const Dashboard = () => {
  return (
    <Wrapper textAlign='center' padding='2rem'>
      {/* <Wrapper padding='1rem' textAlign='center' width='100%'>
          <Image src='https://images-asd.s3-eu-west-1.amazonaws.com/signature/banniere-sitl.png'/>
      </Wrapper> */}
      <Wrapper padding='1rem' textAlign='center'>
        <TextCustom fontSize='3rem' fontWeight='bold'>
          Welcome to My ASD
        </TextCustom>
      </Wrapper>
      <Wrapper padding='1rem' textAlign='center'>
        <TextCustom fontSize='2rem' fontWeight='bold'>
          our multi-service platform
        </TextCustom>
      </Wrapper>
      <CardWrapper>
        <Link to={services.kyanite.path}>
          <ServiceCard
            name='Quickproof'
            logo={services.kyanite.logo}
            tags={['Fiscal', 'VAT', 'Delivery']}
            description='Manage your proofs of transport in all simplicity'
            color='#4a448e'
            bgPosition={{ x: '100%', y: '95%' }}
          />
        </Link>
        <Link to={services.customsDeclaration.path}>
          <ServiceCard
            name='Customs'
            logo={services.customsDeclaration.logo}
            tags={['Customs', 'Transport', 'Fees']}
            description='With you at the border 24 hours a day'
            color='#c24a34'
            bgPosition={{ x: '0%', y: '30%' }}
          />
        </Link>
        <Link to={services.Intrastat.path}>
          <ExternalLink target='_blank' rel='noreferrer' href={services.Intrastat.path}>
              <ServiceCard
                name='Intrastat/EMEBI'
                logo={services.Intrastat.logo}
                tags={['EMEBI', 'Intrastat', 'Customs']}
                description='Manage your Intrastat/EMEBI declarations'
                color='#c24a34'
                bgPosition={{ x: '50%', y: '50%' }}
              />
          </ExternalLink>
        </Link>
      </CardWrapper>
      <Divider />
      <TextCustom fontSize='2rem' fontWeight='bold'>
        Our other services
      </TextCustom>
      <CardWrapper>
        <ExternalLink rel='noreferrer' href={services.spw.path}>
          <ServiceCard
            name='So Posting Worker'
            logo={services.spw.logo}
            tags={['Posting', 'Transport', 'Construction']}
            description='The simplified posting of workers'
            color='#ed6723'
            bgPosition={{ x: '50%', y: '50%' }}
          />
        </ExternalLink>
      </CardWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Dashboard);
